import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

import Button from "@material-ui/core/Button";

const useStyles = makeStyles(styles);
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section3, classes.sectionDarkContact)}>
      <div style={{ padding: "60px 0px 60px 0px" }}>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          align="center"
        >
          <Grid item lg={12} xs={10} sm={10} md={12}>
            <h4 align="center" className={classes.title6}>
              Get A Free Quote
            </h4>
          </Grid>
          <Grid item lg={12} xs={10} sm={10} md={10}>
            <p className={classes.paragraph2}>
              Our team is available to help you with your next project.
            </p>
            <p className={classes.paragraph2}>
              Contact us to get a free quote.
            </p>
          </Grid>
          <Grid item lg={12} xs={10} sm={10} md={12}>
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                marginTop: "10px",
              }}
              variant="contained"
              href="tel:647-370-9868"
            >
              Call Us
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
